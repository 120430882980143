<template>
  <div>
    <a-modal
      v-model="isShowFirstStepModal"
      centered
      :width="800"
      :mask-closable="false"
      ok-text="下一步"
      :ok-button-props="{ props: { loading: firstStepSubmitting } }"
      title="订购套餐"
      @ok="handleValidate"
    >
      <a-row>
        <a-form
          class="iotplt-compact-form"
          :form="firstStepForm"
          @submit="handleValidate"
        >
          <a-col :span="24">
            <a-form-item
              :wrapper-col="{ span: 24 }"
            >
              <common-card-input
                :rows="15"
                ref="iccidInput"
                v-decorator="['iccids', {
                  initialValue: { data: this.iccids },
                  rules: [{ validator: checkIccids }],
                }]"
              />
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </a-modal>

    <a-modal
      v-model="isShowSubmitModal"
      centered
      :width="600"
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting, disabled: !isCanSubmit } }"
      title="订购套餐"
      @ok="handleSubmit"
    >
      <a-row>
        <a-form
          class="iotplt-compact-form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          :form="submitForm"
          @submit="handleSubmit"
        >
          <a-form-item label="已选择卡数">
            <a-input
              disabled
              v-model="this.validIccids.length"
            />
          </a-form-item>

          <a-form-item
            label="选择套餐"
          >
            <a-select
              @change="handleProductChange"
              show-search
              allow-clear
              :filter-option="filterOption"
              :loading="loadingProductionOptions"
              v-decorator="['product_price_history_id', {
                rules: [
                  { required: true, message: '请选择套餐' }
                ]
              }]"
            >
              <a-select-option
                v-for="option in productionOptions"
                :key="option.product_price_history_id+'_'+option.cycles"
                :value="option.product_price_history_id+'_'+option.cycles"
                :cycle="option.cycles"
                :product_price_history_id="option.product_price_history_id"
                :product_type="option.product_type"
              >
                {{ option.product_name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            label="确认周期数量"
          >
            <a-input-number
              :step="1"
              style="width: 100%"
              @change="handleCyclesChange"
              v-decorator="['cycles', {
                rules: [
                  {
                    required: true, message: '请确认周期数量',
                    validator: validateCycles
                  }
                ]
              }]"
            />
          </a-form-item>

          <a-form-item label="订购金额(元)">
            <a-spin :spinning="loadingTotalFee">
              <a-input
                disabled
                v-decorator="['total_fee', {
                  rules: [
                    { validator: validateSubscribeTotalFee }
                  ]
                }]"
              />
            </a-spin>
          </a-form-item>

          <a-form-item
            label="可用额度(元)"
            extra="可用额度=信用额度+账户余额-锁定金额"
          >
            <a-input
              disabled
              v-model="this.availableFund"
            />
          </a-form-item>

          <a-form-item label="立即生效套餐" v-if="isShowIsWie">
            <a-radio-group
              v-decorator="['is_wie',{
                initialValue: false,
                rules: [
                  { required: true, message: '请选择是否立即生效套餐' },
                ]
              }]"
            >
              <a-radio :value="true">
                是
              </a-radio>
              <a-radio :value="false">
                否
              </a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="备注">
            <a-textarea
              v-decorator="['remark', {
                rules: [
                  { max: 200, message: '最多200个字符' },
                ]
              }]"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-item>
        </a-form>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { subscribeCards, subscribeCardsProductsSearch, subscribeCardsCalculateFee, validateSubscribeCards } from '@/api/batch_operation'
import CommonCardInput from '@/components/CardInput/Common'
import { getOriginalUserUid } from '@/utils/token'
import { findAgentFinanceOverview } from '@/api/agent'

export default {
  name: 'BatchOperationSubscribe',
  components: { CommonCardInput },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    iccids: {
      type: Array,
      default() {
        return []
      }
    },
    agentId: {
      type: Number,
      required: true
    }
  },
  data() {
    this.calculateSubscribeTotalFee = this.$lodash.debounce(this.calculateSubscribeTotalFee, 400)
    const isShowFirstStepModal = this.visible
    return {
      loadingProductionOptions: false,
      loadingTotalFee: false,
      productionOptions: [],
      selectedCycles: 0,
      selectedProductPriceHistoryId: 0,
      firstStepSubmitting: false,
      submitting: false,
      validIccids: [],
      availableFund: 0, // 可用额度
      isShowIsWie: false,
      isShowFirstStepModal: isShowFirstStepModal,
      isShowSubmitModal: false,
      isCanSubmit: false, // 是否可以提交订购
      firstStepForm: this.$form.createForm(this, { name: 'set_sim_cards_to_agency_first_step' }),
      submitForm: this.$form.createForm(this, { name: 'set_sim_cards_to_agency_submit' })
    }
  },
  watch: {
    // 步骤中任何一个弹窗打开，则 isShow 应为 true
    isShowFirstStepModal(val) {
      this.isShow = val || this.isShowSubmitModal
    },

    isShowSubmitModal(val) {
      this.isShow = val || this.isShowFirstStepModal
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    topAgentId() {
      return this.$store.getters.userAgentId
    }
  },
  created() {
    this.fetchAvailableFund()
  },
  methods: {
    // 获取可用额度
    fetchAvailableFund() {
      findAgentFinanceOverview(this.agentId).then((res) => {
        if (res.code === 0) {
          this.availableFund = res.data.available
        }
      })
    },

    handleProductChange(value, e) {
      this.selectedCycles = e.data.attrs.cycle
      this.selectedProductPriceHistoryId = e.data.attrs.product_price_history_id

      if (e.data.attrs.product_type === 0) {
        this.isShowIsWie = true
      } else {
        this.isShowIsWie = false
      }

      this.calculateSubscribeTotalFee()
    },

    checkIccids(rule, value, callback) {
      if (!value || !value.inputValue) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入卡号')
        return
      }

      if (value.validateStatus === 'error') {
        callback(value.errorMsg)
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    validateCycles(rule, value, callback) {
      if (value === undefined || value === null || value === '') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入订购周期数量')
        return
      }

      if (!/^[1-9]\d*$/.test(value)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入 1-9999 之间的整数')
        return
      }

      value = parseInt(value)
      if (!value || value < 1 || value > 9999) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入 1-9999 之间的整数')
        return
      }

      if (value !== this.selectedCycles) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('与选择的套餐周期不匹配')
        return
      }

      callback()
    },

    validateSubscribeTotalFee(rule, value, callback) {
      if (value === undefined || value === null || value === '') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请等待订购金额计算完成')
        return
      }

      if (value > this.availableFund) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('账户可用额度低于订购金额，请充值后再进行订购')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    // 清空订购金额
    clearSubscribeTotalFee() {
      this.submitForm.setFieldsValue({
        total_fee: undefined
      })
    },

    // 处理订购周期数量变更
    handleCyclesChange() {
      this.isCanSubmit = false
      this.clearSubscribeTotalFee()
      this.calculateSubscribeTotalFee()
    },

    // 计算订购金额
    calculateSubscribeTotalFee() {
      this.submitForm.validateFields(['cycles'], { force: true }, (err, values) => {
        if (!err) {
          this.loadingTotalFee = true
          subscribeCardsCalculateFee({ iccids: this.validIccids, product_price_history_id: this.selectedProductPriceHistoryId, cycles: values.cycles }).then((res) => {
            if (res.code === 0 && res.data.cycles === values.cycles) {
              if (res.data.is_can_subscribe) {
                this.submitForm.setFieldsValue({
                  total_fee: res.data.total_fee
                })
                this.isCanSubmit = true
              } else {
                this.$error({
                  title: '提示',
                  content: res.data.error
                })
              }
            }
            this.loadingTotalFee = false
          })
        }
      })
    },

    handleValidate(e) {
      e.preventDefault()
      if (this.firstStepSubmitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.firstStepForm.validateFields((err, values) => {
        if (!err) {
          this.firstStepSubmitting = true
          validateSubscribeCards({
            iccids: values.iccids.data
          }).then((res) => {
            if (res.code === 0) {
              if (res.data.is_valid) {
                this.validIccids = values.iccids.data

                // 获取可选套餐
                this.loadingProductionOptions = true

                const originalUserUid = getOriginalUserUid()

                subscribeCardsProductsSearch({
                  iccids: values.iccids.data,
                  original_user_uid: originalUserUid
                }).then((res) => {
                  if (res.code === 0) {
                    this.productionOptions = res.data

                    // 关闭模态框
                    this.isShowSubmitModal = true
                    this.isShowFirstStepModal = false
                  }

                  this.loadingProductionOptions = false
                })

                // // 关闭模态框
                // this.isShowSubmitModal = true
                // this.validIccids = values.iccids.data
                // this.isShowFirstStepModal = false
              } else {
                this.firstStepForm.setFieldsValue({
                  iccids: { inputValue: res.data.result }
                })
                this.$nextTick(() => {
                  this.$refs.iccidInput.handleCards()
                })
              }
            }
            this.firstStepSubmitting = false
          })
        }
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitForm.validateFields((err, values) => {
        if (!err) {
          this.submitting = true

          values.product_price_history_id = parseInt(values.product_price_history_id.split('_')[0])
          subscribeCards({
            ...values,
            top_agent_total_fee: values.total_fee,
            iccids: this.validIccids
          }).then((res) => {
            if (res.code === 0) {
              this.isShowSubmitModal = false
              // 告知父组件已完成
              this.$emit('completed')
              this.$success({
                title: '提交成功',
                content: (
                  <div>
                    <p>操作成功，具体操作结果请至 【系统查询】-【批量操作查询】 查看</p>
                  </div>
                )
              })
            }
            this.submitting = false
          })
        } else {
          this.fetchAvailableFund()
        }
      })
    }
  }
}
</script>
